import boredApe from "./bored-ape.jpg";
import discord from "./discord.png";
import goon1 from "./goon-1.png";
import goon2 from "./goon-2.png";
import goon3 from "./goon-3.png";
import goon4 from "./goon-4.png";
import goon5 from "./goon-5.png";
import goon6 from "./goon-6.png";
import goon7 from "./goon-7.png";
import goon8 from "./goon-8.png";
import goon9 from "./goon-9.png";
import goon10 from "./goon-10.jpg";
import goon11 from "./goon-11.jpg";
import goonMouetteTop from "./goon-mouette-top.png";
import goonMouetteBottom from "./goon-mouette-bottom.png";
import goonShade from "./goon-shade.png";
import gnoog from "./gnoog.png";
import logo from "./logo.png";
import maxiblood from "./maxiblood.png";
import maxigooner from "./maxigooner.jpg";
import maxinaut from "./maxinaut.jpg";
import maxiverse from "./maxiverse.jpg";
import nuage1 from "./nuage-1.png";
import nuage2 from "./nuage-2.png";
import twitter from "./twitter.png";

const sources = {
  boredApe,
  discord,
  goon1,
  goon2,
  goon3,
  goon4,
  goon5,
  goon6,
  goon7,
  goon8,
  goon9,
  goon10,
  goon11,
  goonMouetteTop,
  goonMouetteBottom,
  goonShade,
  gnoog,
  logo,
  maxiblood,
  maxigooner,
  maxinaut,
  maxiverse,
  nuage1,
  nuage2,
  twitter,
};

export default sources;
