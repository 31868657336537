import { Outlet, Link } from "react-router-dom";

import images from "../images";
import Logo from "./logo";

export default function Layout() {
  return (
    <>
      <div className="absolute top-0 left-0 right-0 p-4 z-10">
        <div className="flex flex-row justify-between items-start">
          <Link to="/">
            <Logo />
          </Link>
          <div className="flex flex-row justify-end items-center">
            <a
              href="https://twitter.com/MaxiGoons"
              target="_blank"
              className="mr-2"
            >
              <img src={images.twitter} width="50" />
            </a>
            <a href="https://discord.gg/XS7aNUA6aw" target="_blank">
              <img src={images.discord} width="50" />
            </a>
          </div>
        </div>
      </div>

      <Outlet />
    </>
  );
}
