import contract from "./contract";
import session from "./session";
import ui from "./ui";

const reducers = {
  contract,
  session,
  ui,
};

export default reducers;
