import { BrowserProvider, getWeb3 } from "./gweis";

let browser;

export default async function getBrowser(): Promise<BrowserProvider> {
  if (!browser) {
    const web3 = await getWeb3();

    if (!web3) {
      throw new Error("Web 3 not found");
    }

    browser = new BrowserProvider(web3);
  }

  return browser;
}
