import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Layout } from "./components";
import { Home, Mint } from "./pages";

export default function App() {
  // DOM
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="mint" element={<Mint />} />

          <Route path="*" element={<Error404 />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function Error404() {
  return (
    <div>
      <h2>404</h2>
      <p>Page Not Found</p>
    </div>
  );
}
