export default function getJSON(url) {
  return fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    if (res.status >= 200 && res.status < 300) {
      return res.json();
    }

    throw new Error(res.statusText);
  });
}
