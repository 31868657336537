import React, { FC } from "react";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import cx from "classnames";

import Link, { LinkProps } from "./link";

const ExternalLink: FC<LinkProps> = ({ className, children, ...rest }) => {
  return (
    <Link
      className={cx("flex flex-row items-center", className)}
      {...rest}
      target="_blank"
    >
      <div className="mr-1">{children}</div>
      <ExternalLinkIcon className="h-5 w-5" />
    </Link>
  );
};

export default ExternalLink;
