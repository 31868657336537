import React, { FC } from "react";
import { CheckIcon, XIcon } from "@heroicons/react/outline";

import config from "../config";
import { ExternalLink } from "../lib";

type Props = {
  id: string;
  failed?: boolean;
  mined?: boolean;
};

const Tx: FC<Props> = ({ id, failed = false, mined = false }) => {
  let icon;

  if (mined) {
    icon = <CheckIcon className="ml-1 h-5 w5 text-positive" />;
  } else if (failed) {
    icon = <XIcon className="ml-1 h-5 w5 text-orange" />;
  } else {
    icon = <div className="ml-4 dot-flashing" />;
  }

  return (
    <div className="flex flex-row items-center text-sm p-4">
      <ExternalLink
        className="font-mono underline-offset-1 text-black"
        href={`${config.etherscanUrl}/tx/${id}`}
      >
        {id.slice(0, 10)}...{id.slice(-10)}
      </ExternalLink>
      {icon}
    </div>
  );
};

export default Tx;
