import * as rr from "react-redux";

import store from "./store";

type RootState = ReturnType<typeof store.getState>;

type AppDispatch = typeof store.dispatch;

export const useDispatch = () => {
  const dispatch = rr.useDispatch<AppDispatch>();

  return (type: string, payload?: any) => dispatch({ type, payload });
};

export const useSelector: rr.TypedUseSelectorHook<RootState> = rr.useSelector;
