import { FC } from "react";
import { Listbox } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import cx from "classnames";

export type OptionType = {
  name: number | string;
  value: number | string;
};

type SelectProps = {
  bg?: "divider";
  className?: string;
  label: number | string;
  onChange: (value: number | string) => void;
  options: OptionType[];
  value: number | string;
};

const Select: FC<SelectProps> = ({
  bg = "white",
  className,
  label,
  onChange,
  options,
  value,
}) => {
  return (
    <Listbox value={value} onChange={onChange}>
      <div className="relative">
        <Listbox.Button
          className={cx(
            "relative p-4 pr-10 text-left rounded-xl cursor-default",
            {
              "bg-divider": bg === "divider",
              "bg-white": bg === "white",
            },
            className
          )}
        >
          <span className="block truncate">{label}</span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon
              className="w-5 h-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {options.map(({ name, value: val }) => (
            <Listbox.Option
              key={val}
              className={({ active }) =>
                `cursor-default select-none relative py-2 pl-4 ${
                  active ? "text-blue" : "text-gray"
                }`
              }
              value={val}
            >
              {({ selected }) => (
                <span
                  className={`block truncate ${
                    selected ? "font-medium text-blue" : "font-normal"
                  }`}
                >
                  {name}
                </span>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    </Listbox>
  );
};

export default Select;
