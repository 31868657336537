import { from, fromEventPattern, merge, of } from "rxjs";
import {
  bufferTime,
  catchError,
  filter,
  map,
  startWith,
  switchMap,
  take,
} from "rxjs/operators";
import { ofType } from "redux-observable";
import { BigNumber, ContractTransaction, ethers, Signer } from "ethers";

const action = (type: string, payload?) => ({ type, payload });
const ofAction = (type: string, payload?) => of(action(type, payload));
const startWithAction = (type: string, payload?: any) =>
  startWith({ type, payload });

// const price = ethers.utils.parseEther("0.049");
const price = ethers.utils.parseEther("0");

type TransferEventType = {
  from: string;
  to: string;
  id: BigNumber;
};

const events = [
  //
  // Boot
  //
  // Web3 ?
  (action$, state$, { getBrowser }) =>
    action$.pipe(
      take(1),
      switchMap(() => {
        return from(getBrowser()).pipe(
          map(() => action("browser/fulfilled")),
          catchError(() => ofAction("browser/rejected")),
          startWithAction("browser/pending")
        );
      })
    ),

  // Query transfers
  (action$, state$, { contract, getBrowser }) =>
    action$.pipe(
      take(1),
      switchMap(() =>
        from<Promise<TransferEventType[]>>(
          getBrowser().then((provider) =>
            contract.connect(provider).query("Transfer")
          )
        ).pipe(
          map((transfers) =>
            action(
              "contract/transfers",
              transfers.map(({ from, to, id }) => ({
                from,
                to,
                id: id.toNumber(),
              }))
            )
          )
        )
      )
    ),

  // Sale level
  (action$, state$, { contract, getBrowser }) =>
    action$.pipe(
      take(1),
      switchMap(() =>
        from(
          getBrowser().then((provider) => contract.connect(provider).level())
        ).pipe(map((level) => action("contract/level", level)))
      )
    ),

  // Sale enabled
  (action$, state$, { contract, getBrowser }) =>
    action$.pipe(
      take(1),
      switchMap(() =>
        from(
          getBrowser().then((provider) => contract.connect(provider).enabled())
        ).pipe(map((enabled) => action("contract/enabled", enabled)))
      )
    ),

  (action$, state$, { getJSON }) =>
    action$.pipe(
      take(1),
      switchMap(() =>
        from(
          Promise.all([
            getJSON("/data/leafs-0.2.json"),
            getJSON("/data/leafs-1.2.json"),
          ])
        ).pipe(map(([leafs1, leafs2]) => action("app/leafs", [leafs1, leafs2])))
      )
    ),

  //
  // Runtime
  //

  // Address
  (action$, state$, { getBrowser }) =>
    action$.pipe(
      ofType("browser/fulfilled"),
      switchMap(() =>
        from(
          getBrowser().then((browser) =>
            browser
              .getAccount()
              .then((signer) =>
                signer ? signer.getAddress() : Promise.resolve("")
              )
          )
        ).pipe(map((address) => action("signer/address", address)))
      )
    ),

  // Listen to new Transfer events
  (action$, state$, { contract, getBrowser }) =>
    action$.pipe(
      ofType("contract/transfers"),
      take(1),
      switchMap(() =>
        from(getBrowser()).pipe(
          switchMap((provider) =>
            fromEventPattern(
              (handler) => contract.connect(provider).on("Transfer", handler),
              (handler) => contract.connect(provider).off("Transfer", handler)
            ).pipe(
              bufferTime(1000),
              filter((arr) => arr.length > 0),
              map((arr) =>
                action(
                  "app/transfers",
                  arr.map((ev) => ({
                    from: ev[0],
                    to: ev[1],
                    id: ev[2].toNumber(),
                  }))
                )
              )
            )
          )
        )
      )
    ),

  // Connect
  (action$, state$, { getBrowser }) =>
    action$.pipe(
      ofType("app/connect"),
      switchMap(() =>
        from(
          getBrowser().then((browser) =>
            browser.requestAccount().then((signer) => signer.getAddress())
          )
        ).pipe(
          map((address) => action("app/connect/fulfilled", address)),
          catchError(() => ofAction("app/connect/rejected")),
          startWithAction("app/connect/pending")
        )
      )
    ),

  // Claim
  (action$, state$, { getBrowser, contract }) =>
    action$.pipe(
      ofType("app/claim"),
      switchMap(() => {
        const level = state$.value.contract.level;
        const leafs = state$.value.session.leafs1;

        return from<Promise<Signer>>(
          getBrowser().then((browser) => {
            return browser.requestAccount();
          })
        ).pipe(
          switchMap((signer) => {
            return from<Promise<string>>(signer.getAddress()).pipe(
              switchMap((address) => {
                const leaf = ethers.utils.keccak256(address);
                const proof = leafs[leaf];

                if (level < 3 && proof) {
                  return from<Promise<ContractTransaction>>(
                    contract.connect(signer).claim(proof)
                  ).pipe(
                    switchMap((tx) => {
                      return merge(
                        ofAction("app/claim/tx", tx),
                        from(tx.wait()).pipe(
                          map(() => action("app/claim/fulfilled")),
                          catchError(() => ofAction("app/claim/rejected")),
                          startWithAction("app/claim/pending")
                        )
                      );
                    }),
                    catchError(() => ofAction("app/claim/error", "metamask"))
                  );
                }

                return ofAction("app/claim/error", "list");
              })
            );
          })
        );
      })
    ),

  // Pre-sell
  (action$, state$, { getBrowser, contract }) =>
    action$.pipe(
      ofType("app/preSell"),
      switchMap(({ payload: amount }) => {
        return from<Promise<Signer>>(
          getBrowser().then((browser) => browser.requestAccount())
        ).pipe(
          switchMap((signer) => {
            return from<Promise<string>>(signer.getAddress()).pipe(
              switchMap((address) => {
                const leafs = state$.value.session.leafs2;
                const leaf = ethers.utils.keccak256(address);

                const proof = leafs[leaf];

                if (proof) {
                  return from<Promise<ContractTransaction>>(
                    contract
                      .connect(signer)
                      .presale(amount, proof, { value: price.mul(amount) })
                  ).pipe(
                    switchMap((tx) => {
                      return merge(
                        ofAction("app/mint/tx", tx),
                        from(tx.wait()).pipe(
                          map(() => action("app/mint/fulfilled")),
                          catchError(() => ofAction("app/mint/rejected"))
                        )
                      );
                    }),
                    catchError(() => ofAction("app/mint/error", "metamask"))
                  );
                }

                return ofAction("app/mint/error", "list");
              })
            );
          })
        );
      })
    ),

  // Sell
  (action$, state$, { getBrowser, contract }) =>
    action$.pipe(
      ofType("app/sell"),
      switchMap(({ payload: amount }) => {
        return from<Promise<ContractTransaction>>(
          getBrowser().then((browser) =>
            browser
              .requestAccount()
              .then((signer) =>
                contract
                  .connect(signer)
                  .buy(amount, { value: price.mul(amount) })
              )
          )
        ).pipe(
          switchMap((tx) => {
            return merge(
              ofAction("app/mint/tx", tx),
              from(tx.wait()).pipe(
                map(() => action("app/mint/fulfilled")),
                catchError(() => ofAction("app/mint/rejected"))
              )
            );
          }),
          catchError(() => ofAction("app/mint/error", "metamask"))
        );
      })
    ),
];

export default events;
