import React from "react";
import { ExternalLinkIcon } from "@heroicons/react/outline";

import config from "../config";
import images from "../images";
import { useSelector, useDispatch } from "../hooks";
import { Button, Caption, Col, Container, Text } from "../lib";
import MintForm from "./mint-form";
import Tx from "./tx";

const RESERVE = 707;

export default function Minter() {
  // Hooks
  const dispatch = useDispatch();

  // UI
  const error = useSelector((state) => state.ui.error);
  const connecting = useSelector((state) => state.ui.connecting);
  const claiming = useSelector((state) => state.ui.claiming);
  const loading = useSelector((state) => state.ui.loading);
  const minting = useSelector((state) => state.ui.minting);

  // Session
  const address = useSelector((state) => state.session.address);
  const leaf = useSelector((state) => state.session.leaf);
  const hash = useSelector((state) => state.session.hash);
  const leafs1 = useSelector((state) => state.session.leafs1);
  const leafs2 = useSelector((state) => state.session.leafs2);
  const pending = useSelector((state) => state.session.pending);
  const web3 = useSelector((state) => state.session.web3);

  // Contract
  const balances = useSelector((state) => state.contract.balances);
  const tokens = useSelector((state) => state.contract.tokens);
  const enabled = useSelector((state) => state.contract.enabled);
  const level = useSelector((state) => state.contract.level);
  const supply = useSelector((state) => state.contract.supply);

  // Methods
  const handleConnect = () => dispatch("app/connect");

  const handleClaim = () => dispatch("app/claim");

  const handlePreSell = (amount) => dispatch("app/preSell", amount);

  const handleSell = (amount) => dispatch("app/sell", amount);

  // DOM
  const proof0 = Boolean(leafs1[leaf]);
  const proof1 = Boolean(leafs2[leaf]);

  const balance = balances[address];
  const tokenIds = tokens[address] || [];

  const claimed = proof0 && balance >= 1;
  const minted = proof1 && balance >= (proof0 ? 4 : 3);

  // const ended = supply === config.maxSupply;
  const ended = true;

  const mintMaxAmount = level === 2 ? 3 : 5;

  // const mintDisabled = level === 2 ? ended : level < 1 || !proof1 || minted;
  const mintDisabled = true;

  const mintHandle = level === 2 ? handleSell : handlePreSell;

  const mintLabel = level === 2 ? "Public Sale" : "Goonlist";

  let content;

  if (loading || !address || !enabled) {
    content = (
      <Button
        loading={connecting}
        disabled={!web3 || !enabled || loading}
        white
        onClick={handleConnect}
      >
        Connect Wallet
      </Button>
    );
  } else {
    content = (
      <div className="flex flex-row">
        <Col.C className="mr-2">
          <Button
            disabled={!proof0 || claimed}
            loading={claiming}
            className="mb-2"
            onClick={handleClaim}
          >
            Claim
          </Button>
          <Caption>(Early goons)</Caption>
        </Col.C>
        <Col.C>
          <MintForm
            max={mintMaxAmount}
            disabled={mintDisabled}
            loading={minting}
            onSubmit={mintHandle}
            className="mb-2"
          />
          <Caption>({mintLabel})</Caption>
        </Col.C>
      </div>
    );
  }

  const remaining = config.maxSupply - RESERVE - supply;

  return (
    <Container>
      <Col.C>
        <img src={images.goonShade} width="300" className="mt-16 mb-4" />

        <div className="mb-8">{content}</div>

        {Boolean(hash) && (
          <Tx
            id={hash}
            failed={pending === "error"}
            mined={pending === "success"}
          />
        )}

        {Boolean(error) && <p className="text-negative mb-4">{error}</p>}

        {web3 && remaining > 0 && <Text>0 goons left</Text>}

        {ended && <p className="text-negative p-4">Sold out !</p>}

        <div className="mt-8 grid grid-cols-1 xs:grid-cols-2 gap-8 md:grid-cols-4">
          {tokenIds.map((id) => (
            <a
              key={id}
              href={`https://opensea.io/assets/0x5465a6f97b6c8b9016611de75508404fb5704ab6/${id}`}
              target="_blank"
              className="block bg-white rounded-xl drop-shadow-md transition-transform hover:scale-105 p-4"
            >
              <img src={images.goonShade} />
              <div className="flex flex-row justify-between items-center">
                <div className="font-medium">{id}</div>
                <ExternalLinkIcon className="h-5 w-5" />
              </div>
            </a>
          ))}
        </div>
      </Col.C>
    </Container>
  );
}
