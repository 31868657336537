import { applyMiddleware, combineReducers, createStore } from "redux";
import { combineEpics, createEpicMiddleware } from "redux-observable";
import { composeWithDevTools } from "redux-devtools-extension";

import epics from "./events";
import services from "./services";
import reducers from "./state";

// Epics
const rootEpic = combineEpics(...epics);

const epicMiddleware = createEpicMiddleware({ dependencies: services });

// Store
const rootReducer = combineReducers(reducers);

const enhancer = composeWithDevTools(applyMiddleware(epicMiddleware));

const store = createStore(rootReducer, enhancer);

// Run epics
epicMiddleware.run(rootEpic);

// console.log(epics, rootEpic, services, reducers, store);

store.dispatch({ type: "app/boot" });

export default store;
