type ConfigType = {
  chainId: number;
  contractAddress: string;
  infuraId: string;
  maxSupply: number;
  etherscanUrl: string;
  openSeaUrl: string;
};

let contractAddress;

if (process.env.CHAIN_ID !== "1") {
  let params = new URL(document.location as any).searchParams;
  contractAddress = params.get("contract");
}

const config: ConfigType = {
  chainId: Number(process.env.CHAIN_ID),
  contractAddress: contractAddress || process.env.CONTRACT_ADDRESS,
  infuraId: process.env.INFURA_ID,
  maxSupply: Number(process.env.MAX_SUPPLY),
  etherscanUrl: process.env.ETHERSCAN_URL,
  openSeaUrl: process.env.OPENSEA_URL,
};

export default config;
