import React, { FC } from "react";

const Container: FC = ({ children, ...rest }) => {
  return (
    <div className="mx-auto max-w-5xl" {...rest}>
      <div className="px-8">{children}</div>
    </div>
  );
};

export default Container;
