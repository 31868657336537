import React, { FC, useEffect, useState } from "react";

import { Button, Row } from "../lib";
import Select, { OptionType } from "./select";

type Props = {
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  max: number;
  onSubmit: (amount: number) => void;
};

const MintForm: FC<Props> = ({
  className,
  disabled = false,
  loading = false,
  max,
  onSubmit,
}) => {
  // Hooks
  const [amount, setAmount] = useState<number>(max);
  const [amounts, setAmounts] = useState<OptionType[]>([]);

  useEffect(() => {
    setAmounts(createOptions(max));
  }, [max]);

  // Methods
  const handleSubmit = () => onSubmit(amount);

  const handleChange = (value) => setAmount(Number(value));

  // DOM
  return (
    <Row.C className={className}>
      <Button
        disabled={disabled || !amount}
        loading={loading}
        white
        onClick={handleSubmit}
        className="rounded-tr-none rounded-br-none"
      >
        Mint
      </Button>
      <Select
        className="rounded-tl-none rounded-bl-none"
        bg="divider"
        label={amount}
        value={amount}
        onChange={handleChange}
        options={amounts}
      />
    </Row.C>
  );
};

export default MintForm;

function range(length) {
  return Array.from({ length }, (val, index) => index + 1);
}

function createOptions(max) {
  return range(max).map((index) => ({ name: index, value: index }));
}
