import React, { FC, HTMLAttributes } from "react";
import cx from "classnames";

export type HTMLViewProps = HTMLAttributes<HTMLDivElement>;

type ColType = FC<HTMLViewProps> & {
  C: FC<HTMLViewProps>;
};

const Col: ColType = ({ className, ...rest }) => {
  return <div className={cx("flex flex-col", className)} {...rest} />;
};

const C: FC<HTMLViewProps> = ({ className, ...rest }) => {
  return (
    <div
      className={cx("flex flex-col justify-center items-center", className)}
      {...rest}
    />
  );
};

Col.C = C;

export default Col;
