import React, { FC, ButtonHTMLAttributes } from "react";
import cx from "classnames";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  compact?: boolean;
  loading?: boolean;
  white?: boolean;
  href?: string;
};

const baseClassName = "font-medium rounded-xl py-4 px-6 text-center";

const Button: FC<ButtonProps> = ({
  children,
  className: userClassName,
  compact = false,
  disabled = false,
  loading = false,
  white = false,
  href,
  ...rest
}) => {
  const className = cx(
    "button",
    baseClassName,
    {
      "bg-white text-black": white,
      "bg-black text-white": !white,
      "cursor-not-allowed disabled:opacity-60": disabled,
      "button--loading": loading,
    },
    userClassName
  );

  if (href) {
    return (
      <button
        type="button"
        className={className}
        {...rest}
        onClick={() => window.open(href, "_blank").focus()}
      >
        <span className="button__text">{children}</span>
      </button>
    );
    // return <a className={className} href={href} {...rest} />;
  }

  return (
    <button
      type="button"
      className={className}
      disabled={loading || disabled}
      {...rest}
    >
      <span className="button__text">{children}</span>
    </button>
  );
};

export default Button;
