import produce from "immer";

const initialState = {
  claiming: false,
  connecting: false,
  loading: true,
  error: "",
  minting: false,
};

export default produce((state, { type, payload, meta }) => {
  switch (type) {
    case "contract/enabled":
      if (!payload) {
        state.error = "Sale is not yet open";
      }
      break;

    case "app/leafs": {
      state.loading = false;
      break;
    }

    // PENDING
    case "app/connect/pending":
      state.connecting = true;
      break;

    case "app/claim":
      state.error = "";
      state.claiming = true;
      break;

    case "app/preSell":
    case "app/sell":
      state.error = "";
      state.minting = true;
      break;

    // ERROR
    case "app/claim/error":
    case "app/mint/error":
      state.claiming = false;
      state.minting = false;

      if (payload === "metamask") {
        state.error =
          "Metamask error. Please accept transaction and make sure you have enough funds";
      } else if (type === "app/claim/error") {
        state.error = "You are not an Early Goon";
      } else if (type === "app/mint/error") {
        state.error = "You are not in the Goonlist";
      }
      break;

    // REJECTED
    case "app/claim/rejected":
    case "app/mint/rejected":
      state.claiming = false;
      state.minting = false;
      state.error = "Your transaction failed. Please check etherscan";
      break;

    case "browser/rejected":
    case "app/connect/rejected":
      state.connecting = false;
      state.error = "Your browser is not Web 3 compatible";
      break;

    // FULFILLED
    case "app/connect/fulfilled":
      state.connecting = false;
      break;

    case "app/claim/fulfilled":
      state.claiming = false;
      break;

    case "app/mint/fulfilled":
      state.minting = false;
      break;

    default:
      break;
  }
}, initialState);
