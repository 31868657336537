import React, { FC, AnchorHTMLAttributes } from "react";
import cx from "classnames";

export type LinkProps = AnchorHTMLAttributes<HTMLAnchorElement>;

const Link: FC<LinkProps> = ({ className, children, ...rest }) => {
  return (
    <a className={cx("text-blue underline", className)} {...rest}>
      {children}
    </a>
  );
};

export default Link;
