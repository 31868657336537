import React, { FC } from "react";
import cx from "classnames";

import { HTMLViewProps } from "./col";

type RowType = FC<HTMLViewProps> & {
  C: FC<HTMLViewProps>;
  L: FC<HTMLViewProps>;
  R: FC<HTMLViewProps>;
  LR: FC<HTMLViewProps>;
};

const Row: RowType = ({ className, ...rest }) => {
  return <div className={cx("flex flex-row", className)} {...rest} />;
};

const C: FC<HTMLViewProps> = ({ className, ...rest }) => {
  return (
    <div
      className={cx("flex flex-row justify-center items-center", className)}
      {...rest}
    />
  );
};

const L: FC<HTMLViewProps> = ({ className, ...rest }) => {
  return (
    <div
      className={cx("flex flex-row justify-start items-center", className)}
      {...rest}
    />
  );
};

const R: FC<HTMLViewProps> = ({ className, ...rest }) => {
  return (
    <div
      className={cx("flex flex-row justify-end items-center", className)}
      {...rest}
    />
  );
};

const LR: FC<HTMLViewProps> = ({ className, ...rest }) => {
  return (
    <div
      className={cx("flex flex-row justify-between items-center", className)}
      {...rest}
    />
  );
};

Row.C = C;
Row.L = L;
Row.R = R;
Row.LR = LR;

export default Row;
