import { useEffect, useRef } from "react";

export default function useInterval(callback, delay: number) {
  const savedCallback = useRef<() => {}>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }

    let id = setInterval(tick, delay);

    return () => clearInterval(id);
  }, [delay]);
}
