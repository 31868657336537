import { Contract, ContractInterface, providers, Signer } from "ethers";
import { Provider } from "@ethersproject/abstract-provider";
import { getStatic } from "@ethersproject/properties";

const { JsonRpcProvider, Web3Provider } = providers;

const web3 = new Promise((resolve, reject) => {
  document.addEventListener("readystatechange", () => {
    if (document.readyState === "complete") {
      resolve((window as any).ethereum);
    }
  });
});

export function getWeb3() {
  return web3;
}

export class BrowserProvider extends Web3Provider {
  requestAccounts(): Promise<Array<string>> {
    return this.send("eth_requestAccounts", []).then(
      (accounts: Array<string>) => {
        return accounts.map((a) => this.formatter.address(a));
      }
    );
  }

  async requestAccount() {
    const addresses = await this.requestAccounts();

    if (addresses.length) {
      return this.getSigner(addresses[0]);
    }

    return null;
  }

  async getAccount() {
    const addresses = await this.listAccounts();

    if (addresses.length) {
      return this.getSigner(addresses[0]);
    }

    return null;
  }
}

export class LocalhostProvider extends JsonRpcProvider {
  constructor(chainId = 1337) {
    super("http://localhost:8545", chainId);
  }
}

export class SmartContract extends Contract {
  constructor(
    addressOrName: string,
    contractInterface?: ContractInterface,
    signerOrProvider?: Signer | Provider
  ) {
    let abi = contractInterface;

    if (!contractInterface) {
      abi = getStatic<() => string[]>(new.target, "getAbi")();
    }

    super(addressOrName, abi, signerOrProvider);
  }

  static getAbi() {
    return [];
  }

  async query(eventName) {
    const event = this.interface.getEvent(eventName);

    const events = await this.queryFilter(eventName);

    return events.map(({ args }) => this.mapEventArgs(event, args));
  }

  mapEventArgs(event, args) {
    const result = {};

    for (let i = 0; i < event.inputs.length; i++) {
      result[event.inputs[i].name] = args[i];
    }

    return result;
  }
}

export class ERC721 extends SmartContract {
  static getAbi() {
    return [
      // ERC721
      "function tokenURI(uint256 id) public view returns (string)",

      // ERC721Enumerable
      "function totalSupply() public view returns (uint256)",
      "function balanceOf(address owner) public view returns (uint256)",
      "function ownerOf(uint256 id) public view returns (address)",
      "function tokenOfOwnerByIndex(address owner, uint256 index) public view returns (uint256)",

      // Events
      "event Transfer(address indexed from, address indexed to, uint256 indexed id)",
    ];
  }
}
